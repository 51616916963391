<template>
    <div>
        <ts-page-title
            :title="$t('loanCurrency.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('loanCurrency.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button type="info" ghost @click="addNew">{{
                    $t("addNew")
                }}</Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('loanCurrency.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="loan_type_id">
                    {{
                        row.loan_type ? row.loan_type.loan_type_en : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="currency_id">
                    {{ row.currency ? row.currency.currency_name_en : "" }}
                </template>
                <template slot-scope="{ row }" slot="min_size">
                    <strong class="tw-text-yellow-500">{{ formatCurrencyWithCode(row.min_size, row.currency) }}</strong>
                </template>
                <template slot-scope="{ row }" slot="max_size">
                    <strong class="tw-text-green-500">{{ formatCurrencyWithCode(row.max_size, row.currency) }}</strong>
                </template>
                <template slot-scope="{ row }" slot="min_rate">
                    {{ row.min_rate }} %
                </template>
                <template slot-scope="{ row }" slot="max_rate">
                    {{ row.max_rate }} %
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-4"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <!-- form-action -->
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('loanCurrency.pageTitle')"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>

<script>
import { debounce } from "lodash";
import { mapGetters, mapState } from "vuex";
import FromAction from "./form";

export default {
    name: "loanCurrencyIndex",
    components: {
        FromAction
    },
    data() {
        return {
            loading: false,
            showForm: false,
            width: "width: 300px"
        };
    },
    computed: {
        ...mapState("creditOperation/loanCurrency", ["resources", "pagination"]),
        ...mapGetters(["formatCurrencyWithCode"]),
        search: {
            get() {
                return this.$store.state.creditOperation.loanCurrency.search;
            },
            set(newValue) {
                this.$store.commit("creditOperation/loanCurrency/SET_SEARCH", newValue);
                this.$store.commit("creditOperation/loanCurrency/RESET_CURRENT_PAGE");
            }
        },
        columns() {
            return [
                {
                    title: this.$t("loanCurrency.loanType"),
                    slot: "loan_type_id",
                    sortable: true
                },
                {
                    title: this.$t("loanCurrency.currency"),
                    slot: "currency_id",
                    sortable: true
                },
                {
                    title: this.$t("loanCurrency.minSize"),
                    slot: "min_size",
                    sortable: true,
                    align: "right"
                },
                {
                    title: this.$t("loanCurrency.maxSize"),
                    slot: "max_size",
                    sortable: true,
                    align: "right"
                },
                {
                    title: this.$t("loanCurrency.minRate"),
                    slot: "min_rate",
                    sortable: true,
                    align: "center"
                },
                {
                    title: this.$t("loanCurrency.maxRate"),
                    slot: "max_rate",
                    sortable: true,
                    align: "center"
                },
                {
                    title: this.$t("actions"),
                    key: "action",
                    slot: "action",
                    fixed: "right",
                    align: "center",
                    className: "tw-space-x-2",
                    width: 90
                }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("creditOperation/loanCurrency/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        onEdit(record) {
            this.$store.commit("creditOperation/loanCurrency/SET_EDIT_DATA", record);
            this.showForm = true;
            this.$refs.form_action.fetchResource();
        },
        clearEdit() {
            this.showForm = false;
            this.$refs.form_action.clearInput();
            this.$store.commit("creditOperation/loanCurrency/SET_EDIT_DATA", {});
        },
        addNew() {
            this.$store.commit("creditOperation/loanCurrency/SET_EDIT_DATA", {});
            this.showForm = true;
            this.$refs.form_action.fetchResource();
            this.$refs.form_action.clearInput();
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LOAN CURRENCY",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({ search });
        }, 500)
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("creditOperation/loanCurrency/RESET_STATE");
        next();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    }
};
</script>
